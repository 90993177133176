.ql-toolbar {
  border: 1px solid #c4c4c4;
  border-radius: 4px 4px 0 0;
}

.editor.edit-mode .ql-container {
  border: 1px solid #c4c4c4;
  border-radius: 0 0 4px 4px;
}

.ql-container p {
  margin: 5px 0;
  font-size: 16px;
}

.ql-editor {
  font-size: 16px;
}

.editor-disabled {
  background-color: rgb(237, 237, 237);
}

@mixin ql-editor-rows($rows) {
  $row-content-height: 23px;
  $row-vertical-margin: 5px;
  $vertical-padding: 12px;

  $row-height: $row-content-height + ($row-vertical-margin * 2);
  min-height: ($vertical-padding * 2) + ($rows * $row-height);
}

.ql-editor-min-rows-1 .ql-editor { @include ql-editor-rows(1) }
.ql-editor-min-rows-2 .ql-editor { @include ql-editor-rows(2) }
.ql-editor-min-rows-3 .ql-editor { @include ql-editor-rows(3) }
.ql-editor-min-rows-4 .ql-editor { @include ql-editor-rows(4) }
.ql-editor-min-rows-5 .ql-editor { @include ql-editor-rows(5) }
.ql-editor-min-rows-6 .ql-editor { @include ql-editor-rows(6) }
.ql-editor-min-rows-7 .ql-editor { @include ql-editor-rows(7) }
.ql-editor-min-rows-8 .ql-editor { @include ql-editor-rows(8) }
.ql-editor-min-rows-9 .ql-editor { @include ql-editor-rows(9) }
.ql-editor-min-rows-10 .ql-editor { @include ql-editor-rows(10) }
.ql-editor-min-rows-11 .ql-editor { @include ql-editor-rows(11) }
.ql-editor-min-rows-12 .ql-editor { @include ql-editor-rows(12) }

.editor.editor-with-error {
  border-color: red;
}
